/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import $ from 'jquery';
import { sectionProduction } from "./section/sectionProduction";

$(function() { 
    //MODAL RGPD START  
    let date =  new Date().toISOString();
    let rgpdLimit = localStorage.getItem('rgpdMediawanStudioDateLimit');
    if(rgpdLimit !== null) {
        if(date > rgpdLimit)
            $('#rgpdModal').fadeIn(1000);
        else if(date < rgpdLimit)
            $('#rgpdModal').hide();
    } else {
        $('#rgpdModal').fadeIn(1000);
    }
    $('#accept').on('click', (e) => {
        localStorage.setItem('rgpdMediawanStudioDateLimit', new Date(new Date().setMonth(new Date().getMonth()+13)).toISOString());
        $('#rgpdModal').fadeOut(1000);
    });
    $('#refuse').on('click', (e) => {
        $('#rgpdModal').fadeOut(1000);
    });
    //LANG DETECT START
    let valueParamLang = window.location.pathname.substring(1);
    let filterValueParamLang = valueParamLang[0] + valueParamLang[1];
    let langSelectedToDisp = filterValueParamLang.length > 0 ? filterValueParamLang : 'fr';
    if(typeof $('.currentLangSelected')[0] !== 'undefined')
        $('.currentLangSelected')[0].innerHTML = langSelectedToDisp.toUpperCase();
    
    //SEARCH
    $('.searchBar').on('change keyup', function (e) {
        if(e.target.value.length >= 3) {
            $('.searchContainer').css('display','flex');
            $('.skipHeader').css('display', 'none');
            let count = 0;

            $('.titleOneResultSearch').map((i) => {
                let regex = new RegExp(`(${e.target.value.toLowerCase()}+)`, 'g');
                let str = $('.titleOneResultSearch')[i].innerHTML.toLowerCase();
                let test = regex.test(str)

                if( test === true ) {
                    let target = $('.titleOneResultSearch')[i];
                    console.log(target)
                    $(target).parent().parent().parent().css('display','block');
                    count++;
                } else if(test === false) {
                    let target = $('.titleOneResultSearch')[i];
                    $(target).parent().parent().parent().css('display','none');
                }
                $('.countSearch')[0].innerHTML = '('+ count +')';
            });
        } else {
            $('.skipHeader').css('display', 'block');
            $('.searchContainer').css('display','none');
        }

    });
    //CURSOR 
    $(document).on('mousemove', function(e) {
        if(window.innerWidth > 820)
            $('.imgCursor').css({'display':'flex', 'left': e.clientX+"px", 'top': e.clientY+"px"});
    });
    //MENU BURGER
    $('#openrightnav').on('click', () => {
        if ($(window).width() <= 768)
            $('.nav-right').css({'right': 0, 'width': '100%'});
        else
            $('.nav-right').css({'right': 0, 'width': '620px'});
    });
    $('#closerightnav').on('click', () => {
        if ($(window).width() <= 768)
            $('.nav-right').css({'right': '-100%', 'width': '-100%'});
        else
            $('.nav-right').css({'right': 0,'width': 0});
    });
    //ICON TRANSLATE
    $('.translateIcon, .currentLangSelected').on('click', () => {
        let translateClass = $('.translationSelection')[0].classList;
        if(translateClass.length === 1) {
            $('.translationSelection').addClass('showTranslation');
        }else {
            $('.translationSelection').removeClass('showTranslation');
        }
    });
    //ICONSEARCH
    $('.icon-search').on('click', () => {
        $('.searchBar').addClass('showSearchBar');
    });
    //FILTER TEAM
    $('.inputMenuTeam').on('click', (e) => {
        let listFilterTeam = $('.menuTeamMobile')[0].classList;
        if(listFilterTeam.length === 1) {
            $('.menuTeamMobile').addClass('showMenuTeamMobile');
        }else {
            $('.menuTeamMobile').removeClass('showMenuTeamMobile');
        }
    });
    //SEARCHBAR
    $('body').on('click', (e) => {
        if(e.target.className !== 'searchBar showSearchBar' && e.target.className !== 'icon-search') {
            $('.searchBar').removeClass('showSearchBar');
        }
    });

    //SORT BY
    $('.sortIcon').on('click', () => {
        let sortClass = $('.sortBySelection')[0].classList;
        if(sortClass.length === 1) {
            $('.sortBySelection').addClass('showSortBy');
        }else {
            $('.sortBySelection').removeClass('showSortBy');
        }
    });
    //PLAYER VIDEO
    $('.inputModalPlayer').on('click', (e) => {
        let value = e.target.dataset.player;
        $(`.${value}`).fadeIn();
    });
    $('.closeArrowVideosPop').on('click', (e) => {
        let value = e.target.dataset.player;
        let iframe = document.querySelector(`.${value} iframe`);
        let ifrSrc = iframe.src;
        iframe.src = ifrSrc;
        $(`.${value}`).fadeOut();
    });
    //PLAYER VIDEO SECTIONTITLE HOME
    $('.imgVideoInputIcon').on('click', (e) => {
        $('#modalVideo').show();
    });
    $('.closeArrowPhotosPop').on('click', (e) => {
        $('#photosCarousel').fadeOut();
    });

});
sectionProduction();
