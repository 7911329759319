import $ from 'jquery';

export const sectionProduction = () => {
    
    $(function() { 
        let allFilter = $('#firstInputFilter')[0];
        if(allFilter !== undefined)
        document.querySelectorAll('.columnProduction').forEach((el) => {
            let itemCate = JSON.parse(el.dataset.category);
            $(el).hide();
            itemCate.forEach((cate) => {
                //console.log(cate.toLowerCase())
                if(cate.toLowerCase() === allFilter.dataset.filter.replace(/ /g, '').toLowerCase())
                    $(el).show();
            });
        })

        //FILTER PROD
        $('.inputListFilterProd').on('click', () => {
            let listFilterProd = $('.listFilterProdMobile')[0].classList;
            if(listFilterProd.length === 1) {
                $('.listFilterProdMobile').addClass('showListFilterProd');
            }else {
                $('.listFilterProdMobile').removeClass('showListFilterProd');
            }
        });
        
        //FILTER CATEGORY PROD
        $('.inputMenuFilterProd').on('click', (e) => {
            $('.inputMenuFilterProd').css('color', '#fff');
            let value = e.target.innerHTML.toLowerCase();
            let listProd = document.querySelectorAll('.columnProduction');
            $(e.target).css('color', 'red');
            $('h1.title')[0].innerHTML = value.toUpperCase();

            listProd.forEach((el) => {
                let itemCate = JSON.parse(el.dataset.category);
                $(el).hide();
                itemCate.forEach((cate) => {
                    if(cate.toLowerCase() === value.replace(/ /g,''))
                        $(el).show();
                });
            });
            $('.listFilterProdMobile').removeClass('showListFilterProd');
            $('.inputDisplayFilterProdMobile')[0].innerHTML = e.target.innerHTML;
        });

    });
}